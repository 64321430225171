import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-6" }
const _hoisted_2 = { class: "d-flex justify-start align-start mt-4 mb-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ml-6 mt-3 d-flex flex-column text-start" }
const _hoisted_5 = { class: "body--medium--large color-text" }
const _hoisted_6 = { class: "status-mobile" }
const _hoisted_7 = {
  key: 0,
  class: "ml-2 my-auto"
}
const _hoisted_8 = {
  key: 1,
  class: "ml-2 my-auto"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  class: "text-right color-text-success body--medium--medium text-no-wrap ml-8",
  style: {"line-height":"normal"}
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "color-text-success pb-1 pr-1" }
const _hoisted_13 = { class: "d-flex justify-space-between w-100" }
const _hoisted_14 = { class: "color-text" }
const _hoisted_15 = { class: "pr-7" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  class: "text-right color-text-success body--medium--medium text-no-wrap",
  style: {"line-height":"normal"}
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "color-text-success pb-1 pr-1" }

import {
  onMounted, ref, watch,
} from 'vue';
import { OrderDetail, TripDetail } from '@/core/types/TripDetail';


export default /*@__PURE__*/_defineComponent({
  __name: 'TripStatus',
  props: {
    trip: {},
    hiddeTimeline: { type: Boolean }
  },
  setup(__props: any) {


const props = __props;

const actualState = ref({
  label: '',
  icon: '',
  index: 0,
});

const checkActualState = (trip: TripDetail) => {
  trip.orderDetail.forEach((order: OrderDetail, index) => {
    if (index === 0) {
      actualState.value = {
        index,
        label: `${order.label} ${order?.destination || ''}`,
        icon: order.label,
      };
    } else if (order.currentState) {
      actualState.value = {
        index,
        label: `${order.label} ${order?.destination || ''}`,
        icon: index === 5 ? 'Entrega de documentación' : index === 8 ? 'Arribo al cliente' : order.label,
      };
    }
  });
};
onMounted(() => {
  checkActualState(props.trip);
});
watch(() => props.trip, (trip) => {
  checkActualState(trip);
});


return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `../illustrations/iconsApp/${actualState.value.icon}.svg`,
        class: "mt-2",
        style: {"width":"3rem"},
        alt: "icono de estado"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "body--bold--medium color-text-subtitle" }, "Estados de viaje:", -1)),
        _createElementVNode("p", _hoisted_5, _toDisplayString(actualState.value.label), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (!_ctx.hiddeTimeline)
        ? (_openBlock(), _createBlock(_component_v_timeline, {
            key: 0,
            direction: "horizontal",
            "truncate-line": "both",
            align: "start",
            "line-color": "#BBBBBB",
            "line-inset": -40
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trip?.orderDetail, (order, index) => {
                return (_openBlock(), _createBlock(_component_v_timeline_item, {
                  key: index,
                  size: index === actualState.value.index ? "40" : "14",
                  "dot-color": "success",
                  "fill-dot": index <= actualState.value.index,
                  icon: index === actualState.value.index ? "mdi-navigation-variant" : ""
                }, {
                  icon: _withCtx(() => [
                    (actualState.value.index === index)
                      ? (_openBlock(), _createBlock(_component_v_icon, {
                          key: 0,
                          size: "18",
                          color: "white",
                          class: "icon-navigation"
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("mdi-near-me ")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["size", "fill-dot", "icon"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trip?.orderDetail, (order, index) => {
        return (_openBlock(), _createBlock(_component_v_expansion_panels, {
          class: "mb-2",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  class: "pa-2",
                  "expand-icon": order?.subStates ? 'mdi-menu-down' : '',
                  "collapse-icon": order?.subStates ? 'mdi-menu-up' : ''
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      cols: "auto",
                      class: _normalizeClass(["color-text actual-state w-100", (actualState.value.index === index) && (actualState.value.label !== 'Viaje a finalizar' && actualState.value.label !== 'Finalizar viaje') ? '' : 'd-flex'])
                    }, [
                      _createVNode(_component_v_col, { class: "d-flex" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            (order.isCompleted)
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 0,
                                  size: "18",
                                  class: "status-success mr-1"
                                }, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createTextVNode("mdi-check-circle-outline ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (order.currentState)
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 1,
                                  size: "18",
                                  class: "status-success mr-1"
                                }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("mdi-truck-fast-outline ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (!order.isCompleted && !order.currentState)
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 2,
                                  size: "18",
                                  class: "status-disabled mr-1"
                                }, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode(" mdi-progress-clock ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          (order.destination)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(order.label) + " " + _toDisplayString(order.destination || ''), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(order.label), 1))
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_col, {
                        cols: "3",
                        class: "actual-state me-auto py-1"
                      }, {
                        default: _withCtx(() => [
                          ((actualState.value.index === index) && (actualState.value.label !== 'Viaje a finalizar' && actualState.value.label !== 'Finalizar viaje'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("p", _hoisted_10, [
                                  _createVNode(_component_v_icon, { class: "current-status-icon" }, {
                                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                                      _createTextVNode("mdi-circle-small")
                                    ])),
                                    _: 1
                                  }),
                                  _cache[6] || (_cache[6] = _createTextVNode(" Estado actual "))
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          ((actualState.value.index !== index && order.dateTime) || (actualState.value.label === 'Viaje a finalizar' || actualState.value.label === 'Finalizar viaje'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_12, _toDisplayString((order.dateTime?.split(' ')[0])?.split('-')
                        .reverse()
                        .join('/')
                        ?.slice(0, 5) || ''), 1),
                                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                  _createTextVNode(" " + _toDisplayString((order.dateTime?.split(' ')[1])?.slice(0, 5) || ''), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["expand-icon", "collapse-icon"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order?.subStates, (subState, subIndex) => {
                  return (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                    key: subIndex,
                    class: "pa-0"
                  }, {
                    default: _withCtx(() => [
                      (subState.isOptional)
                        ? (_openBlock(), _createBlock(_component_v_list, {
                            key: 0,
                            class: "pa-0"
                          }, {
                            default: _withCtx(() => [
                              (subState.isCompleted && (!order.subStates[index - 1]?.isCompleted || !order.subStates[index + 1].isCompleted))
                                ? (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: 0,
                                    class: "text-left color-text"
                                  }, {
                                    default: _withCtx(() => [
                                      (subState.isCompleted)
                                        ? (_openBlock(), _createBlock(_component_v_icon, {
                                            key: 0,
                                            size: "18",
                                            class: "status-success mr-2"
                                          }, {
                                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                              _createTextVNode("mdi-check-circle-outline ")
                                            ])),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      (!subState.isCompleted)
                                        ? (_openBlock(), _createBlock(_component_v_icon, {
                                            key: 1,
                                            size: "18",
                                            class: "status-disabled mr-2"
                                          }, {
                                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                                              _createTextVNode(" mdi-progress-clock ")
                                            ])),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      _createTextVNode(" " + _toDisplayString(subState.label) + " " + _toDisplayString((order.dateTime?.split(' ')[0])?.split('-')
                .reverse()
                .join('/')
                ?.slice(0, 5) || ''), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024))
                        : (order.label !== "Descarga en destinatario")
                          ? (_openBlock(), _createBlock(_component_v_list, {
                              key: 1,
                              class: "pa-0"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item, {
                                  vi: "",
                                  class: "text-left color-text"
                                }, {
                                  default: _withCtx(() => [
                                    (subState.isCompleted || (order.dateTime && order.label == "Calificación"))
                                      ? (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 0,
                                          size: "18",
                                          class: "status-success mr-2"
                                        }, {
                                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                                            _createTextVNode("mdi-check-circle-outline ")
                                          ])),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 1,
                                          size: "18",
                                          class: "status-disabled mr-2"
                                        }, {
                                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                                            _createTextVNode(" mdi-progress-clock ")
                                          ])),
                                          _: 1
                                        })),
                                    _createTextVNode(" " + _toDisplayString(subState.label) + " ", 1),
                                    (subState.label == "Calificación del servicio" && order.dateTime)
                                      ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                          key: 2,
                                          class: "ml-9 mt-2 color-text"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
                                              return _createVNode(_component_v_icon, {
                                                key: index,
                                                class: _normalizeClass([index <= subState.stars ? 'star-selected' : 'star-no-selected'])
                                              }, {
                                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                                  _createTextVNode(" mdi-star ")
                                                ])),
                                                _: 2
                                              }, 1032, ["class"])
                                            }), 64))
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    (subState.label == "Nombre de quien recibió el pedido" && order.dateTime)
                                      ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                          key: 3,
                                          class: "ml-9 mt-2 color-text"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(subState.receivedName), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          : (subState.isSelected && ((subState.label == "Descarga correcta" && !order.subStates[1].isSelected) || subState.label == "Devolución del pedido"))
                            ? (_openBlock(), _createBlock(_component_v_list, {
                                key: 2,
                                class: "pa-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, {
                                    vi: "",
                                    class: "text-left color-text"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        size: "18",
                                        class: "status-success mr-2"
                                      }, {
                                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                                          _createTextVNode("mdi-check-circle-outline ")
                                        ])),
                                        _: 1
                                      }),
                                      _createTextVNode(" " + _toDisplayString(subState.label) + " ", 1),
                                      (subState.label == "Devolución del pedido")
                                        ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                            key: 0,
                                            class: "ml-9 mt-2 color-text"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(subState.comment), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _createVNode(_component_v_timeline, {
      class: "status-desktop",
      side: "end",
      "truncate-line": "both",
      align: "start",
      "line-color": "#BBBBBB"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trip?.orderDetail, (order, index) => {
          return (_openBlock(), _createBlock(_component_v_timeline_item, {
            key: index,
            size: index === actualState.value.index ? "40" : "14",
            "dot-color": "success",
            "fill-dot": index <= actualState.value.index,
            icon: index === actualState.value.index ? "mdi-navigation-variant" : ""
          }, {
            icon: _withCtx(() => [
              (actualState.value.index === index)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "18",
                    color: "white",
                    class: "icon-navigation"
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode("mdi-near-me ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panels, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, {
                        "expand-icon": order?.subStates ? 'mdi-menu-down' : '',
                        "collapse-icon": order?.subStates ? 'mdi-menu-up' : ''
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("p", null, [
                                (order.isCompleted)
                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                      key: 0,
                                      size: "18",
                                      class: "status-success mr-4"
                                    }, {
                                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                                        _createTextVNode("mdi-check-circle-outline ")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (order.currentState)
                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                      key: 1,
                                      size: "18",
                                      class: "status-success mr-4"
                                    }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode("mdi-truck-fast-outline ")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (!order.isCompleted && !order.currentState)
                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                      key: 2,
                                      size: "18",
                                      class: "status-disabled mr-4"
                                    }, {
                                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                                        _createTextVNode(" mdi-progress-clock ")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                _createTextVNode(" " + _toDisplayString(order?.label) + " " + _toDisplayString(order?.destination || ''), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              ((actualState.value.index === index) && (actualState.value.label !== 'Viaje a finalizar' && actualState.value.label !== 'Finalizar viaje'))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("p", _hoisted_17, [
                                      _createVNode(_component_v_icon, { class: "current-status-icon" }, {
                                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                                          _createTextVNode("mdi-circle-small")
                                        ])),
                                        _: 1
                                      }),
                                      _cache[19] || (_cache[19] = _createTextVNode(" Estado actual "))
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              ((actualState.value.index !== index && order.dateTime) || (actualState.value.label === 'Viaje a finalizar'|| actualState.value.label === 'Finalizar viaje'))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                    _createElementVNode("p", _hoisted_19, _toDisplayString((order.dateTime?.split(' ')[0])?.split('-')
                      .reverse()
                      .join('/')
                      ?.slice(0, 5) || ''), 1),
                                    _createElementVNode("p", null, _toDisplayString((order.dateTime?.split(' ')[1])?.slice(0, 5) || ''), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["expand-icon", "collapse-icon"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order?.subStates, (subState, subIndex) => {
                        return (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                          key: subIndex,
                          class: "pa-0"
                        }, {
                          default: _withCtx(() => [
                            (subState.isOptional)
                              ? (_openBlock(), _createBlock(_component_v_list, {
                                  key: 0,
                                  class: "pa-0"
                                }, {
                                  default: _withCtx(() => [
                                    (subState.isCompleted && (!order.subStates[index - 1]?.isCompleted || !order.subStates[index + 1].isCompleted))
                                      ? (_openBlock(), _createBlock(_component_v_list_item, {
                                          key: 0,
                                          class: "text-left color-text"
                                        }, {
                                          default: _withCtx(() => [
                                            (subState.isCompleted)
                                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                                  key: 0,
                                                  size: "18",
                                                  class: "status-success mr-2"
                                                }, {
                                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                    _createTextVNode("mdi-check-circle-outline ")
                                                  ])),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            (!subState.isCompleted)
                                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                                  key: 1,
                                                  size: "18",
                                                  class: "status-disabled mr-2"
                                                }, {
                                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                    _createTextVNode(" mdi-progress-clock ")
                                                  ])),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                            _createTextVNode(" " + _toDisplayString(subState.label) + " " + _toDisplayString((order.dateTime?.split(' ')[0])?.split('-')
                  .reverse()
                  .join('/')
                  ?.slice(0, 5) || ''), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024))
                              : (order.label !== "Descarga en destinatario")
                                ? (_openBlock(), _createBlock(_component_v_list, {
                                    key: 1,
                                    class: "pa-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item, {
                                        vi: "",
                                        class: "text-left color-text"
                                      }, {
                                        default: _withCtx(() => [
                                          (subState.isCompleted && order.label !== "Calificación")
                                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                                key: 0,
                                                size: "18",
                                                class: "status-success mr-2"
                                              }, {
                                                default: _withCtx(() => _cache[22] || (_cache[22] = [
                                                  _createTextVNode("mdi-check-circle-outline ")
                                                ])),
                                                _: 1
                                              }))
                                            : (order.label !== "Calificación")
                                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                                  key: 1,
                                                  size: "18",
                                                  class: "status-disabled mr-2"
                                                }, {
                                                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                    _createTextVNode(" mdi-progress-clock ")
                                                  ])),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true),
                                          _createTextVNode(" " + _toDisplayString(subState.label) + " ", 1),
                                          (subState.label == "Calificación del servicio" && order.dateTime)
                                            ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                                key: 2,
                                                class: "mt-2 color-text"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
                                                    return _createVNode(_component_v_icon, {
                                                      key: index,
                                                      class: _normalizeClass([index <= subState.stars ? 'star-selected' : 'star-no-selected'])
                                                    }, {
                                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                        _createTextVNode(" mdi-star ")
                                                      ])),
                                                      _: 2
                                                    }, 1032, ["class"])
                                                  }), 64))
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : _createCommentVNode("", true),
                                          (subState.label == "Nombre de quien recibió el pedido" && order.dateTime)
                                            ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                                key: 3,
                                                class: "mt-2 color-text"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(subState.receivedName), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (subState.isSelected && ((subState.label == "Descarga correcta" && !order.subStates[1].isSelected) || subState.label == "Devolución del pedido"))
                                  ? (_openBlock(), _createBlock(_component_v_list, {
                                      key: 2,
                                      class: "pa-0"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item, {
                                          vi: "",
                                          class: "text-left color-text"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, {
                                              size: "18",
                                              class: "status-success mr-2"
                                            }, {
                                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                _createTextVNode("mdi-check-circle-outline ")
                                              ])),
                                              _: 1
                                            }),
                                            _createTextVNode(" " + _toDisplayString(subState.label) + " ", 1),
                                            (subState.label == "Devolución del pedido")
                                              ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                                                  key: 0,
                                                  class: "ml-9 mt-2 color-text"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(subState.comment), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["size", "fill-dot", "icon"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})